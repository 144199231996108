/**
* LDG Trawling Service project
* Copyright © winniepukki. All rights reserved.
*
* @license MIT
*/

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en_GB from './locales/en_GB.json';
import de_DE from './locales/de_DE.json';
import et_EE from './locales/et_EE.json';
import lv_LV from './locales/lv_LV.json';
import ru_RU from './locales/ru_RU.json';

const resources = {
    en_GB: {
        translation: en_GB
    },
    de_DE: {
        translation: de_DE
    },
    et_EE: {
        translation: et_EE
    },
    lv_LV: {
        translation: lv_LV
    },
    ru_RU: {
        translation: ru_RU
    }
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: 'en_GB',
    // Ensure the debugging mode is set to *false* in production
    debug: false,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    }
})
    .then(() => {});

export default i18n;
