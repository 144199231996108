/**
* LDG Trawling Service project
* Copyright © winniepukki. All rights reserved.
*
* @license MIT
*/

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './i18n/i18n';
import 'bootstrap/dist/css/bootstrap-grid.css';
import './app/style/main.scss';

import Loading from '@component/Loading';

const App = lazy(() => import(
    /* webpackChunkName: "App" */ '@component/App'
));

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={ <Loading /> }>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
