/**
* LDG Trawling Service project
* Copyright © winniepukki. All rights reserved.
*
* @license MIT
*/
import React from 'react';

import './Loading.style.scss';

class Loading extends React.Component {
    render() {
        return (
            <section
              className="Loading"
            >
              <img
                src="assets/images/logo.png"
                alt="Page loading status"
              />
            </section>
        );
    }
}

export default Loading;
